import uuid4 from "uuid4";

import AudioRecorder from "../components/AudioRecorder";

export default function AdvisorCall() {
  return (
    <AudioRecorder
      title={"상담사"}
      callId={uuid4()}
      speakerDiv={"t1"}
      role={"advisor"}
    />
  );
}
