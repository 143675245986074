import { Client } from "@stomp/stompjs";

const stompClient = (() => {
  let client = null;
  let isConnecting = false;

  // const connect = (callback) => {
  //   if (client && client.active) {
  //     console.warn("STOMP client is already connected.");
  //     return;
  //   }

  //   client = new Client({
  //     webSocketFactory: () =>
  //       new WebSocket(
  //         `${process.env.REACT_APP_GATEWAY_ADV_WEBSOCKET_PROTOCOL}://${process.env.REACT_APP_GATEWAY_ADV_HOST}/adv-gateway/advise`
  //       ),
  //     onConnect: () => {
  //       console.log("STOMP client connected");
  //       if (callback) callback();
  //     },
  //     onDisconnect: () => console.log("STOMP client disconnected"),
  //     onStompError: (err) => console.error("STOMP error:", err),
  //   });

  //   client.activate();
  // };

  const connect = () => {
    if (client && client.active) {
      console.warn("STOMP client is already connected.");
      return Promise.resolve(); // 이미 연결되어 있으면 즉시 반환
    }

    if (isConnecting) {
      console.warn("STOMP client is currently connecting.");
      return Promise.resolve(); // 연결 시도 중인 경우도 반환
    }

    isConnecting = true; // 연결 시도 플래그 설정

    return new Promise((resolve, reject) => {
      client = new Client({
        webSocketFactory: () =>
          new WebSocket(
            `${process.env.REACT_APP_GATEWAY_ADV_WEBSOCKET_PROTOCOL}://${process.env.REACT_APP_GATEWAY_ADV_HOST}/advise`
          ),
        onConnect: () => {
          console.log("STOMP client connected");
          isConnecting = false;
          resolve();
        },
        onStompError: (err) => {
          console.error("STOMP error:", err);
          isConnecting = false;
          reject(err);
        },
        onDisconnect: () => {
          console.log("STOMP client disconnected");
          isConnecting = false;
        },
      });

      client.activate();
    });
  };

  const subscribe = (destination, callback) => {
    if (client && client.connected) {
      client.subscribe(destination, (message) => {
        callback(message);
      });
    } else {
      console.error("Cannot subscribe: STOMP client is not connected.");
    }
  };

  // const send = (destination, data) => {
  //   console.log("Sending message to", client.connected);
  //   if (client && client.connected) {
  //     const payload = JSON.stringify(data);
  //     client.publish({
  //       destination,
  //       body: payload,
  //     });
  //   } else {
  //     console.error("STOMP client is not connected.");
  //   }
  // };
  const send = async (destination, data) => {
    if (!client || !client.connected) {
      console.warn("STOMP client is not connected. Attempting reconnection...");
      await connect();
    }

    if (client && client.connected) {
      client.publish({
        destination,
        body: JSON.stringify(data),
      });
    } else {
      console.error("Failed to send message: STOMP client is not connected.");
    }
  };

  const disconnect = () => {
    if (client) {
      client.deactivate();
      console.log("STOMP client deactivated.");
    }
  };

  // const isConnected = () => {
  //   return client && client.active;
  // };
  const isConnected = () => client && client.connected;

  return { connect, subscribe, send, disconnect, isConnected };
})();

export default stompClient;
