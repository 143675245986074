import uuid4 from "uuid4";

import AudioRecorder from "../components/AudioRecorder";

export default function CustomerCall() {
  return (
    <AudioRecorder
      title={"고객"}
      callId={uuid4()}
      speakerDiv={"r1"}
      role={"customer"}
    />
  );
}
